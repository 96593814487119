export const useConst = () => {
    return {
        bannerLocations: [
            {
                title: "Нүүр хуудас дээрх эхний баннер",
                value: "main",
            }, {
                title: "Дунд хэсгийн нарийн баннер",
                value: "middle",
            }, {
                title: "Дунд хэсгийн багц баннер",
                value: "midPackage",
            }, {
                title: "Доод хэсгийн нарийн баннер",
                value: "footer",
            }, {
                title: "Pop up баннер",
                value: "popUp",
            },
            
        ],
        cities: ["Sydney", "Canberra", "Melbourne", "Brisbane", "Perth", "Adelaide"],
        bondAndRent: [
            { title: "1 week", value: 7 },
            { title: "2 weeks", value: 14 },
            { title: "3 weeks", value: 21 },
            { title: "4 weeks", value: 28 },
        ],
        bedAndBath: [
            { title: "1", value: 1 },
            { title: "2", value: 2 },
            { title: "3", value: 3 },
            { title: "4", value: 4 },
        ],
        parkings: [
            { title: "Машины зогсоолтой", value: "true" },
            { title: "Машины зогсоолгүй", value: "false" },
        ],
        living_room: [
            { title: "Хүнтэй", value: "true" },
            { title: "Хүнгүй", value: "false" },
        ],
        roomTypes: ["Master room", "Second room", "Study room", "Living room"],
        durability: [
            { title: "Шинэ", value: "new" },
            { title: "Шинэвтэр", value: "rather new" },
            { title: "Хуучин", value: "old" }
        ],
        categories: [
            {
                name: "Үл хөдлөх",
                key: "immovable",
                icon: "/categories/vl_hudluh.png",
                subcat: [
                    { name: "Байр түрээслэх/Нэр шилжүүлэх", key: "house" },
                    { name: "Өрөө түрээслэх", key: "room" },
                    { name: "Бусад үл хөдлөх", key: "otherImmo" },
                ],
            },
            {
                name: "Автомашин",
                key: "car",
                icon: "/categories/car.png",
                types: ["Автомашин", "Мотоцикл, Унадаг дугуй", "Авто сэлбэг"],
            },
            {
                name: "Компьютер",
                key: "computer",
                icon: "/categories/computer.png",
                types: ["Суурин компьютер", "Notebook, Laptop", "Принтер, хувилагч, сканнер", "Сэлбэг", "iPad, tablet, kindle", "Чихэвч", "Дагалдах хэрэгсэл", "Бусад технологи"],
            },
            {
                name: "Хоол хүнс",
                key: "food",
                icon: "/categories/food.png",
                // types: ["Төмөр, барилгын багаж", "Цэвэрлэгээний багаж", "Бусад багаж"],
            },
            {
                name: "Гэр ахуйн бараа",
                key: "household",
                icon: "/categories/ger_ahui.png",
                types: ["Гал тогооны хэрэгсэл", "Хивс, дэвсгэр", "Oр, матрас", "Ширээ, сандал", "Тавиур", "Буйдан", "Бусад гэр ахуй"],
            },
            {
                name: "Цахилгаан бараа",
                key: "electronics",
                icon: "/categories/electronic.png",
                types: ["Tелевизор, хөгжим", "Угаалгын машин", "Хөргөгч, хөлдөөгч", "Тень, халаагуур", "Тоос сорогч, индүү", "Агааржуулагч, сэнс", "Бусад цахилгаан бараа"],
            },
            {
                name: "Ажил",
                key: "job",
                icon: "/categories/job.png",
                types: ["Бүтэн цагийн", "Хагас цагийн", "Цагийн", "Гэрээт", "Түр хугацааны"],
            },
            {
                name: "Ажлын багаж",
                key: "tool",
                icon: "/categories/tools.png",
                types: ["Төмөр, барилгын багаж", "Цэвэрлэгээний багаж", "Бусад багаж"],
            },
            
            { name: "Үнэгүй өгнө", key: "free", icon: "/categories/free.png" },
            { name: "Бусад", key: "other", icon: "/categories/busad.png" },
        ],
    }
}
